import Cookie from "js-cookie";
import i18next from "i18next";

import { RESPONSE_ERRORS, RESPONSE_SUCCESS, CURRENT_LANGUAGE } from "../constants/global";
import { DOMAIN } from "../config/config";

export function isLoggedIn() {
  if (getToken()) {
    return true;
  }
  return false;
}

export function setToken(token) {
  Cookie.set("token", token, { domain: DOMAIN, secure: true });
}

export function setRefId(refid) {
  Cookie.set("refid", refid, { domain: DOMAIN });
}

export function setGetParam(name, param) {
  if (!name) {
    return;
  }
  Cookie.set(name, param, { domain: DOMAIN });
}

export function getToken() {
  return Cookie.get("token") || null;
}

export function getRefId() {
  return Cookie.get("refid") || null;
}

export function getReferral() {
  return Cookie.get("referral") || null;
}

export function getGetParam(name) {
  return Cookie.get(name) || null;
}

export function removeToken() {
  Cookie.remove("token", { domain: DOMAIN });
}

export function removeParam(name) {
  Cookie.remove(name, { domain: DOMAIN });
}

export function errorMessage(errors) {
  return {
    type: RESPONSE_ERRORS,
    errors,
  };
}

export function successMessage(success) {
  return {
    type: RESPONSE_SUCCESS,
    success,
  };
}

export function fetchCurrentLanguage() {
  return {
    type: CURRENT_LANGUAGE,
    lang: i18next.language === "zh-CN" ? "cn" : i18next.language,
  };
}

export function setNoticeLocale() {
  Cookie.set("notice_locale", "no", {expires: 365, domain: DOMAIN});
}

export function getNoticeLocale() {
  return Cookie.get("notice_locale") || null;
}

export function setNoticeCookies() {
  Cookie.set("notice_cookies_accept", "yes", {expires: 365, domain: DOMAIN});
}

export function getNoticeCookies() {
  return Cookie.get("notice_cookies_accept") || null;
}

export function setTheme() {
  Cookie.set("theme_dark", "yes");
}

export function getTheme() {
  return Cookie.get("theme_dark") || null;
}

export function removeTheme() {
  Cookie.remove("theme_dark");
}
